import React, { useState, useRef } from 'react'
import { graphql } from "gatsby"
import tw, { styled } from 'twin.macro'

import Layout from "../components/Layout"
import Section from '../components/Section'

import SvgRightArrow from '../../static/img/svg/right-arrow.svg'
import SvgLeftArrow from '../../static/img/svg/left-arrow.svg'
import SvgAdd from '../../static/img/svg/add.svg'
import SvgSubtract from '../../static/img/svg/subtract.svg'

const Intro = styled.div`
    ${tw`
        mt-48
        w-full
    `}
`

// This takes on the size of an h3 as per the custom global styles but every page needs to have an h1 for SEO optimization
const Title = styled.h1`
    ${tw`
        font-bold

        text-4xl sm:text-5xl
        leading-tight sm:leading-tight   
    `}
`

const TableOfContents = styled.div`
    ${tw`
        mt-16
        flex flex-row 
        items-end
        text-lg
    `}
`

const TocDescription = styled.div`
    ${tw`
        flex-shrink
        whitespace-pre-line
    `}

    flex-basis: 0%;
    flex-grow: 2;

    > p:last-child {
        ${tw`
            cursor-pointer
            hover:text-black
            w-max
        `}

        color: darkorange;
    }
`

const TocLinks = styled.div`
    ${tw`
        hidden
        md:flex flex-grow flex-shrink
        justify-end
        mr-16
    `}

    flex-basis: 0%;

    > ul {
        ${tw`
            list-none
            ml-6
        `}

        > li {
            ${tw`
                mb-2
                cursor-pointer
                hover:text-black
            `}

            color: darkorange;
        }
    }
`

const BodySectionInner = styled.div`
    ${tw`
        w-full text-lg
        border-t border-black
    `}

    scroll-margin-top: 5.5rem;          // these 2 are offsets for scrolling to sections 
    scroll-snap-margin-top: 5.5rem;

    > h6 {
        ${tw`
            mt-2
            font-bold
            mb-6
        `}
    }
`

const OpenPositions = styled.div`
    ${tw`
        pt-12
        grid md:grid-cols-2 gap-12
    `}
`

const OpCard = styled.div`
    ${tw`
        border
        font-semibold
        transition-all duration-200
    `}

    border-color: darkorange;

    :hover {
        border-color: #b36200;
        color: darkorange;
    }

    > a {
        ${tw`
            block
            w-full h-full
            p-8
        `}
    }
`

const ProcessAboveMd = styled.div`
    ${tw`
        hidden md:block
        mt-24
    `}
`

const PSteps = styled.div`
    ${tw`
        flex flex-row
        max-w-full
    `}

    width: 800px;
`

const PStepsButton = styled.button`
    ${tw`
        items-start
        relative
        text-left
        flex-grow flex-shrink
        pt-1 px-2
        border-t-2
    `}

    border-color: ${props => props.active ? "darkorange" : "#e5e7eb"};
    flex-basis: 0%;
`

const PStepsDescription = styled.div`
    ${tw`
        my-16
    `}

    max-width: 700px;
`

const PStepsArrows = styled.div`
    ${tw`
        flex
    `}
`

const PStepsArrowBtn = styled.button`
    ${tw`
        px-3
        visible
    `}

    ${({ disabled }) => disabled && tw`
        invisible
    `}

    > svg {
        ${tw`
            w-6 h-6
        `}

        > path {
            fill: darkorange;
        }
    }
`

const ProcessBelowMd = styled.div`
    ${tw`
        block md:hidden
        mt-24
    `}
`

const PItem = styled.div`
    ${tw`
        mb-16
    `}

    :last-child {
        ${tw`
            mb-0
        `}
    }

    > div:first-child {
        ${tw`
            text-xl font-semibold
            mb-6
        `}
    }
`

const Benefits = styled.div`
    ${tw`
        mt-24
        grid md:grid-cols-2 lg:grid-cols-3
        gap-16
        pl-14
    `}
`

const BItem = styled.div`
    ${tw`
        relative
    `}

    > img, svg {
        ${tw`
            w-8 h-8
            absolute top-0
            -translate-x-full
        `}

        left: -20px;
    }

    > div {
        ${tw`
            text-xl font-semibold
            mb-6
        `}
    }
`

const FaqSubheader = styled.div`
    ${tw`
        mt-12
        flex flex-row
        justify-between
    `}

    > button {
        ${tw`
            text-gray-400
            ml-6
        `}
    }
`

const Faq = styled.div`
    ${tw`
        mt-12
        grid md:grid-cols-2
        gap-x-8 gap-y-3
    `}
`

const FaqItem = styled.div`
    ${tw`
        pb-3
        border-b
    `}
`

const FaqItemBtn = styled.button`
    ${tw`
        w-full 
        flex justify-between items-center
        text-left text-xl font-semibold
    `}

    img, svg {
        ${tw`
            w-4 h-4
        `}
    }
`

const FaqItemDescription = styled.div`
    ${tw`
        pt-5
    `}
`

const CareersPage = ({
    data: {
        markdownRemark: {
            frontmatter: { title, seo, tableOfContents, openPositions, process, benefits, faq }
        }
    }
}) => {
    const [activeStepIdx, setActiveStepIdx] = useState(0);
    const [faqStates, setFaqStates] = useState(new Array(faq.data.length).fill(false));
    const sectionRefs = useRef([])

    const addToSectionRefs = (ref) => {
        if (ref && !sectionRefs.current.includes(ref)) {
            sectionRefs.current.push(ref)
        }
    }

    const scrollToSection = (idx) => () => {
        sectionRefs.current[idx].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const toggleFaq = (idx) => () => {
        let newState = [...faqStates]
        newState[idx] = !newState[idx]
        setFaqStates(newState)
    }

    const toggleAllFaq = () => {
        let newStates = [...faqStates];
        if (faqStates.every(f => f === true)) {
            newStates.fill(false)
        } else {
            newStates.fill(true)
        }

        setFaqStates(newStates)
    }

    function convertNumTwoDigits(num) {
        return num.toLocaleString('en-US', {
            minimumIntegerDigits: 2
        })
    }

    return (
        <Layout title={seo.title} description={seo.description}>
            <Section>
                <Intro>
                    <Title>{title}</Title>
                    <TableOfContents>
                        <TocDescription>
                            <p>{tableOfContents.description}</p>
                            {tableOfContents.descriptionLink && (
                                <p onClick={scrollToSection(tableOfContents.descriptionLink.scrollToSection - 1)}>
                                    {tableOfContents.descriptionLink.text}
                                </p>
                            )}
                        </TocDescription>
                        <TocLinks>
                            <ul>
                                {tableOfContents.links.map((label, linkIdx) => (
                                    <li onClick={scrollToSection(linkIdx)}>
                                        {label} &#8628;
                                    </li>
                                ))}
                            </ul>
                        </TocLinks>
                    </TableOfContents>
                </Intro>
            </Section>

            {openPositions && (
                <Section>
                    <BodySectionInner ref={addToSectionRefs}>
                        <h6>{openPositions.title}</h6>
                        {openPositions.data?.length > 0 ? (
                            <OpenPositions>
                                {openPositions.data.map(({ label, url }, opIdx) => (
                                    <OpCard key={opIdx}>
                                        <a
                                            href={url}
                                            target="_blank"
                                        >
                                            {label}
                                        </a>
                                    </OpCard>

                                ))}
                            </OpenPositions>
                        ) : (
                            <div>No open positions at the moment</div>
                        )}

                    </BodySectionInner>
                </Section>
            )}

            {process && (
                <Section>
                    <BodySectionInner ref={addToSectionRefs}>
                        <h6>{process.title}</h6>
                        <p>
                            {process.subtitle}
                        </p>
                        <ProcessBelowMd>
                            {process.data.map(({ label, description }, stepIdx) => (
                                <PItem key={stepIdx}>
                                    <div>{stepIdx + 1}. {label}</div>
                                    <p>{description}</p>
                                </PItem>
                            ))}
                        </ProcessBelowMd>
                        <ProcessAboveMd>
                            <PSteps>
                                {process.data.map(({ label, description }, stepIdx) => (
                                    <PStepsButton
                                        active={activeStepIdx === stepIdx}
                                        onClick={() => setActiveStepIdx(stepIdx)}
                                        key={stepIdx}
                                    >
                                        <div>{convertNumTwoDigits(stepIdx + 1)}</div>
                                        <strong>{label}</strong>
                                    </PStepsButton>
                                ))}
                            </PSteps>
                            <PStepsDescription>
                                {process.data[activeStepIdx].description}
                            </PStepsDescription>
                            <PStepsArrows>
                                <PStepsArrowBtn
                                    disabled={activeStepIdx === 0}
                                    onClick={() => setActiveStepIdx(prev => --prev)}
                                >
                                    <SvgLeftArrow />
                                </PStepsArrowBtn>
                                <PStepsArrowBtn
                                    disabled={activeStepIdx === process.data.length - 1}
                                    onClick={() => setActiveStepIdx(prev => ++prev)}
                                >
                                    <SvgRightArrow />
                                </PStepsArrowBtn>
                            </PStepsArrows>
                        </ProcessAboveMd>
                    </BodySectionInner>
                </Section>
            )}

            {benefits && (
                <Section>
                    <BodySectionInner ref={addToSectionRefs}>
                        <h6>{benefits.title}</h6>
                        <Benefits>
                            {benefits.data.map(({ head, body, image }, benefitIdx) => (
                                <BItem key={benefitIdx}>
                                    <img src={image.relativePath ? `/img/${image.relativePath}` : image} alt={`${head} icon`} />
                                    <div>{head}</div>
                                    <p>{body}</p>
                                </BItem>
                            ))}
                        </Benefits>
                    </BodySectionInner>
                </Section>
            )}

            {faq && (
                <Section>
                    <BodySectionInner ref={addToSectionRefs}>
                        <h6>{faq.title}</h6>
                        <FaqSubheader>
                            <p>{faq.subtitle}</p>
                            <button onClick={toggleAllFaq}>
                                {faqStates.every(f => f === true) ? "Collapse" : "Expand"} all
                            </button>
                        </FaqSubheader>
                        <Faq>
                            {faq.data.map(({ label, description }, faqIdx) => (
                                <FaqItem key={faqIdx}>
                                    <FaqItemBtn onClick={toggleFaq(faqIdx)}>
                                        <div>{label}</div>
                                        <div>
                                            {faqStates[faqIdx] ? (
                                                <SvgSubtract />
                                            ) : (
                                                <SvgAdd />
                                            )}
                                        </div>
                                    </FaqItemBtn>
                                    {faqStates[faqIdx] && (
                                        <FaqItemDescription>
                                            {description}
                                        </FaqItemDescription>
                                    )}
                                </FaqItem>
                            ))}
                        </Faq>
                    </BodySectionInner>
                </Section>
            )}
        </Layout>
    )
}

export default CareersPage

export const pageQuery = graphql`
    query careersQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
            frontmatter {
                title
                seo {
                    title 
                    description
                }
                tableOfContents {
                    description
                    descriptionLink {
                        text
                        scrollToSection
                    }
                    links
                }
                openPositions {
                    title
                    data {
                        label
                        url
                    }
                }
                process {
                    title
                    subtitle
                    data {
                        label
                        description
                    }
                }
                benefits {
                    title
                    data {
                        head
                        body
                        image {
                            relativePath
                        }
                    }
                }
                faq {
                    title
                    subtitle
                    data {
                        label
                        description
                    }
                }
            }
        }
    }
`